<template>
  <div class="wrapper">
    <section class="section-profile-cover section-shaped my-0">
      <!-- Circles background -->
      <!-- <img class="bg-image" src="img/pages/mohamed.jpg" style="width: 100%;" /> -->
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-secondary"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </section>
    <section class="section bg-secondary" v-if="hasEvent">
      <div class="container">
        <div class="card card-profile shadow mt--300">
          <div class="px-4">
            <div class="row justify-content-center" v-if="event.degree.college.college_logo">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image" style="height:120px">
                  <a @click="goToPage('/'+event.degree.college.college_unique_username)" style="cursor:pointer">
                    <img :src="imageUrl+event.degree.college.college_logo" class="rounded-circle" />
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center"
              >
                
              </div>
              <div class="col-lg-4 order-lg-1">
                
              </div>
            </div>
            <div class="text-center mt-5">
              <h3 @click="goToPage('/'+event.degree.college.college_unique_username)" style="cursor:pointer">{{event.degree.college.college_name}}</h3>
              <!-- <div class="h6 font-weight-300">
                <i class="ni location_pin mr-2"></i>Moulvibazar
              </div> -->
              <div class="h6">
                {{event.event_name}}
              </div>
              <!-- <div>
                <i class="ni education_hat mr-2"></i>University of Computer
                Science
              </div> -->
            </div>
            <div class="mt-5 py-5 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div class="container">
                     <!-- <base-alert icon="ni ni-support-16" dismissible type="danger" v-if="studentInfo=='Error'">
                      <div slot="text">
                        <strong>Error!</strong> Student ID not fount for this event. Please try again with proper ID.
                      </div>
                    </base-alert> -->

                    <div data-v-49c4e179="" role="alert" class="alert alert-danger alert-dismissible" v-if="showAlert">
                      <span class="alert-inner--icon"><i class="ni ni-support-16"></i></span>
                      <span class="alert-inner--text">
                        <div data-v-49c4e179="">
                          <strong data-v-49c4e179="">Error!</strong> Student ID not found for this event. Please try again with proper ID. 
                        </div>
                      </span>
                      <button type="button" aria-label="Close" class="close" @click="toggleAlert(false)">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                    <div class="col-lg-6 col-md-10 mx-auto" v-if="studentInfo!='Show'">
                      <div class="card bg-secondary shadow border-0">
                        <div class="card-body px-lg-5 py-lg-5">
                          <form role="form" @submit.prevent="submitStudentID">
                            <div class="form-group mb-3">
                              <div class="form-group input-group-alternative" placeholder="Student ID" style="text-align:left">
                                <label style="padding:3px 10px;margin-bottom:0px"> Student ID </label>
                                <input type="text" placeholder="Student ID" class="form-control" name="student_id" v-model="studentId" required>
                              </div>
                            </div>

                            <div class="form-group mb-3" v-if="event.student_type=='open'" style="text-align:left">
                              <div class="form-group input-group-alternative" placeholder="Student Name">
                                <label style="padding:3px 10px;margin-bottom:0px"> Student Name </label>
                                <input type="text" placeholder="Student Name" class="form-control" name="student_name" v-model="studentForm.student_name" required>
                              </div>
                            </div>
                            <div class="form-group mb-3" v-if="event.amount_type=='open'" style="text-align:left">
                              <div class="form-group input-group-alternative" placeholder="Amount">
                                <label style="padding:3px 10px;margin-bottom:0px"> Amount </label>
                                <input type="text" placeholder="Amount" class="form-control" name="amount" v-model="studentForm.amount" required>
                              </div>
                            </div>
                           
                            <div class="form-group input-group-alternative" style="text-align:left">
                              <!-- <label class="sr-only" for="inlineFormInputGroup">Student Mobile</label> -->
                              <label style="padding:3px 10px;margin-bottom:0px"> Student Mobile</label>
                              <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">+880</div>
                                </div>
                                <input type="number" class="form-control" id="student_mobile" name="student_mobile" min="1000000000" max="9999999999" v-model="studentMobile" placeholder="Student Mobile" oninvalid="this.setCustomValidity('10 Digit required')" oninput="this.setCustomValidity('')" required>
                              </div>
                            </div>
                            <div class="form-group mb-3" v-if="eventId=='390'" style="text-align:left">
                              <div class="form-group input-group-alternative" placeholder="Roll">
                                <label style="padding:3px 10px;margin-bottom:0px"> Please enter your registration number </label>
                                <input type="text" placeholder="registration number" class="form-control" name="roll" v-model="studentForm.roll" required>
                              </div>
                            </div>
                            <div class="text-center">
                              <button type="submit" class="btn my-0 btn-primary">Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12" v-if="studentInfo=='Show'">
                       <div class="card">
                        <div class="card-body p-0">
                          <div class="container">
                            <h5 class="title mt-1 bg-info" style="color:#d8f7fd;font-weight:bold;padding:10px;margin-right: -15px;margin-left: -15px;">
                              Student Information
                            </h5>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> Student ID </label>
                                  <input  class="form-control" :value="student.student_id" disabled>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> Student Name </label>
                                  <input class="form-control" :value="student.student_name" disabled>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> Class </label>
                                  <input class="form-control" :value="student.class" disabled>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> Group </label>
                                  <input class="form-control" :value="student.group" disabled>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> Section </label>
                                  <input class="form-control" :value="student.section" disabled>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> Roll </label>
                                  <input class="form-control" :value="student.roll" disabled>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> Fee </label>
                                  <input class="form-control" :value="student.amount_str" disabled>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> Service Charge </label>
                                  <input class="form-control" :value="student.service_charge_str" disabled>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> Total Amount </label>
                                  <input class="form-control" :value="student.total_amount_str" disabled>
                                </div>
                              </div>
                              <div class="col-md-6" v-if="student.payment_type=='multiple'">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> Already Paid </label>
                                  <input class="form-control" :value="amountAlreadyPaidStr" disabled>
                                </div>
                              </div>
                              <div class="col-md-6" v-if="student.payment_type=='multiple'">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> To be Paid </label>
                                  <input class="form-control" :value="amountToBePaidStr" disabled>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group has-label" style="text-align:left">
                                  <label> Payment Status </label>
                                  <input class="form-control" :value="student.payment_status" disabled>
                                </div>
                              </div>
                            </div>
                            
                            <h4 style="border-radius:4px;text-transform: uppercase;padding:3px" v-if="student.payment_status=='PAID' && !showPaymentMessage" class="bg-info text-white">Payment Successful</h4>
                            <h4 style="border-radius:4px;text-transform: uppercase;padding:3px" v-if="student.payment_status=='PAID' && showPaymentMessage" class="bg-success text-white">{{paymentMessage}}</h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12" v-if="amountToBePaidTotalAmount.length>0 && (student.payment_type=='multiple' && !showPaymentMessage)">
                       <div class="card">
                        <div class="card-body p-3">
                          <div class="container">
                            <form role="form" @submit.prevent="payNow">
                              <div class="row">
                                <div class="col-md-3"></div>
                                <div class="col-md-6">
                                  <div class="form-group has-label" style="text-align:left">
                                    <label>Select Amount</label>
                                    <select class="form-control" v-model="eventSetting.amount" placeholder="Select Amount" required>
                                      <option v-for="(amount,index) in amountToBePaidTotalAmount" :key="index" selected>{{amount}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-md-3"></div>
                                <div class=" col-md-12 text-center" style="margin-bottom:10px">
                                  <button type="submit" class="btn btn-primary" v-if="!payBtnLoading">Pay Now</button>
                                  <base-button type="primary" class="btn btn-primary" v-else disabled="">
                                    <spinner class="spinner-border-sm"></spinner>
                                    Loading...
                                  </base-button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                          

                    <div class="tables-2 mb-5" v-if="studentInfo=='Show'">
                      <!-- <div class="container-fluid">
                        <div class="row">
                          <div class="col-md-10 mx-auto">
                            <div>
                              <div class="table-responsive mb-0">
                                <table class="table table-pricing">
                                  <tbody>
                                    <tr class="bg-info">
                                      <td class="text-white">
                                        <b>Student Information</b>
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr><td>Student ID</td><td class="text-center">{{student.student_id}}</td></tr>
                                    <tr><td>Student Name</td><td class="text-center">{{student.student_name}}</td></tr>
                                    <tr><td>Class</td><td class="text-center">{{student.class}}</td></tr>
                                    <tr><td>Group</td><td class="text-center">{{student.group}}</td></tr>
                                    <tr><td>Section</td><td class="text-center">{{student.section}}</td></tr>
                                    <tr><td>Roll</td><td class="text-center">{{student.roll}}</td></tr>
                                    <tr><td>Amount</td><td class="text-center">{{student.amount}}</td></tr>
                                    <tr><td>Payment Status</td><td class="text-center"><b>{{student.payment_status}}</b></td></tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <div class="container">
                        <div class="row align-items-center">
                            <div class="mt-lg-3 col-md-12">
                                <div class="input-group mb-3">
                                  <input id="amount" type="hidden" :value="eventSetting.amount"/>
                                  <input id="callBackUrl" type="hidden" :value="eventSetting.callBackUrl"/>
                                  <input id="account" type="hidden" :value="eventSetting.account"/>
                                  <input id="password" type="hidden" :value="eventSetting.password"/>
                                  <input id="requesterReferenceId" type="hidden" :value="eventSetting.requesterReferenceId"/>
                                  <input id="invoiceNumber" type="hidden" :value="eventSetting.invoice_number"/>
                                  <input id="authToken" type="hidden" :value="eventSetting.auth_token"/>
                                  <input id="authApiKey" type="hidden" :value="eventSetting.auth_api_key"/>
                                  <input id="type" type="hidden" :value="eventSetting.type"/>
                                  <input id="url" type="hidden" :value="eventSetting.url"/>
                                </div>
                                <div v-if="student.payment_status!='PAID' && student.payment_type!='multiple'">
                                  <button type="button" class="btn btn-primary" v-if="!payBtnLoading" @click="payNow()">Pay Now</button>
                                  <base-button type="primary" class="btn btn-primary" v-else disabled="">
                                    <spinner class="spinner-border-sm"></spinner>
                                    Loading...
                                  </base-button>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="container" v-if="student.payment_status==='PAID' && event.degree.college.invoice_status==='on'">
                        <div class="row align-items-center" v-if="completeTransactions.length > 0">
                            <div class="mt-lg-5 col-md-12" v-for="transaction in completeTransactions" :key="transaction.id">
                                <button type="button" class="btn btn-primary" @click="goToBlankPage('/'+username+'/'+eventId+'/'+studentId + '/' + transaction.requester_reference_id)">Download Payment Receipt ( {{transaction.college_amount }} BDT )</button>
                            </div>
                        </div>
                      </div>
                    </div>
                    <button id="tokenButton" type="button" class="btn btn-primary" style="display:none" onclick="iframeInitiate(this)">Pay Now</button>
                    <button id="loadSandBoxJs" type="button" class="btn btn-primary" style="display:none" onclick="loadJS('sandbox',false)">SandBox</button>
                    <button id="loadLiveJs" type="button" class="btn btn-primary" style="display:none" onclick="loadJS('production',false)">Live</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg-secondary" v-else>
      <div class="container">
        <div class="card card-profile shadow mt--300">
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image" style="height:120px">
                  <a href="javascript:;">
                    <img :src="baseUrl+'/img/tap_profile.png'" @click="goToPage('/')" class="rounded-circle" />
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center"
              >
                
              </div>
              <div class="col-lg-4 order-lg-1">
                
              </div>
            </div>
            <div class="text-center mt-5">
              <div data-v-49c4e179="" role="alert" class="alert alert-danger alert-dismissible">
                <span class="alert-inner--icon"><i class="ni ni-support-16"></i></span>
                <span class="alert-inner--text">
                  <div data-v-49c4e179="">
                    <strong data-v-49c4e179="">Error!</strong> Event not found.
                    Please try again with proper Event. 
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import RequestService from '../services/request.service';
  import Spinner from "@/components/Spinner";

export default {
  components: {
    Spinner
  },
  data() {
    return {
      hasEvent: true,
      baseUrl: window.location.origin,
      payBtnLoading:false,
      showAlert: false,
      showPaymentMessage: false,
      paymentMessage: "",
      event:{
        event_name: "",
        degree_id: "",
        end_date_time: "",
        start_date_time: "",
        status: "",
        student_type: "",
        amount_type: "",
        type: "",
        fixed_amount: "",
        degree:{
          college: {
            college_banner:  "",
            college_logo:  "",
            college_name:  "",
            college_unique_username:  "",
            id:  "",
            status:  "",
            deploy_status: "",
            rate: 0,
            invoice_status: "off",
            minimum_tap_amount: null,
            maximum_tap_amount: null,
            credential:{
              id: "",
              user_name: "",
              password: ""
            },
          },
          college_id: "",
          degree_name: "",
          id: "",
          status: ""
        }
      },
      imageUrl: "",
      apiUrl: "",
      username: "",
      studentId: "",
      studentMobile: "",
      eventId: "",
      studentInfo: "Hide",
      student: {
        id: "",
        student_id: "",
        student_name: "",
        event_id: "",
        class: "",
        group: "",
        section: "",
        roll: "",
        amount: "",
        amount_str: "",
        service_charge: 0,
        service_charge_str: "",
        total_amount: 0,
        total_amount_str: "",
        payment_status: "UNPAID",
        payment_type: "single"
      },
      studentForm:{
        student_name: "",
        class: "",
        amount: "",
        group: "GROUP",
        section: "SECTION",
        roll: "ROLL",
        reg_type: "REG_TYPE"
      },
      completeTransactions: [],
      amountToBePaid: [],
      amountToBePaidStr: "",
      amountAlreadyPaid: [],
      amountAlreadyPaidStr: "",
      amountToBePaidTotalAmount: [],
      eventSetting: {
        callBackUrl:window.location,
        amount: "",
        account: "",
        password: "",
        requesterReferenceId: "",
        invoice_number: "",
        auth_token: "",
        auth_api_key: "",
        type: "",
        url: "",
      }
    };
  },
  mounted(){
    window.test=this;
    this.eventId = this.$route.params.id;
    this.username = this.$route.params.username;
    // this.imageUrl = process.env.VUE_APP_IMAGE_URL;
    this.imageUrl = 'https://tap.fee.com.bd/images/colleges/';

    // this.apiUrl = process.env.VUE_APP_API_URL;
    this.apiUrl = 'https://xggk3kgs0j.execute-api.ap-southeast-1.amazonaws.com/';
    this.getData();    
  },
  methods: {
    async getData(){
      let response =  await RequestService.get('event/'+this.$route.params.id+'?include=degree.college.credential');
      if(response){
        this.event = response;
        if(this.eventId === '390'){
          this.studentForm.roll = ""
        }
        this.eventSetting.type = this.event.degree.college.deploy_status;
        this.hasEvent = true;
      }else{
        this.hasEvent = false;
      }
    },
    goToPage(pageLink){
      this.$router.push(pageLink);
    },
    goToBlankPage(pageLink){
       let route = this.$router.resolve({path: pageLink});
      window.open(route.href, '_blank');
    },
    async submitStudentID(){
      let response;
      if(this.event.student_type=="open"){
        if(this.event.amount_type == "fixed"){
          this.studentForm.amount = this.event.fixed_amount;
        }
        this.studentForm.class = this.event.event_name;
        response = await RequestService.post("student-create/" + this.studentId + "/event/" + this.$route.params.id + "?include=completeTransactions",this.studentForm);
        response = response.data;
      }else{
        if(this.event.amount_type=="open"){
          response = await RequestService.get("student/" + this.studentId + "/event/" + this.$route.params.id + "?include=completeTransactions&amount=" + this.studentForm.amount);
        }else{
          if(this.eventId==="390"){
            response = await RequestService.get("student/" + this.studentId + "/event/" + this.$route.params.id + "?include=completeTransactions&roll=" + this.studentForm.roll);
          }
          response = await RequestService.get("student/" + this.studentId + "/event/" + this.$route.params.id + "?include=completeTransactions");
        }
      }
      if(response){
        this.amountToBePaidTotalAmount = [];
        this.amountToBePaid = [];
        this.student.id = response.id;
        this.student.student_id = response.student_id;
        this.student.student_name = response.student_name;
        this.student.event_id = response.event_id;
        this.student.class = response.class;
        this.student.group = response.group;
        this.student.section = response.section;
        this.student.roll = response.roll;
        this.student.amount = response.amount;
        this.student.payment_status = response.payment_status;
        if (typeof response.completeTransactions !== 'undefined') {
          if(response.completeTransactions.length > 0) {
            this.completeTransactions = response.completeTransactions;
          // console.log(this.completeTransactions);
        }
        }
        
        let amount = 0;
        let rate = parseFloat(this.event.degree.college.rate)/100;
        let serviceCharge = 0;
        let serviceChargeDecimel = 0;
        let serviceChargeV = 0;
        let amount_str = this.student.amount;
        if (typeof response.payment_type !== 'undefined') {
          if(response.payment_type== "multiple"){
            this.student.payment_type = "multiple";
            amount_str = amount_str.replaceAll("#",",");
            this.amountToBePaidStr = "";
            this.amountAlreadyPaidStr = "";
            this.amountToBePaid = response.amount_to_be_paid;
            this.amountAlreadyPaid = response.amount_already_paid;
            if(this.amountAlreadyPaid.length>0){
              for ( let k = 0; k < this.amountAlreadyPaid.length; ++k) {
                if(this.amountAlreadyPaidStr != ""){
                  this.amountAlreadyPaidStr += ","+this.amountAlreadyPaid[k];
                }else{
                  this.amountAlreadyPaidStr = this.amountAlreadyPaid[k];
                }
              }
            }
            if(this.amountToBePaid.length>0){
              for(let j = 0;j<this.amountToBePaid.length;++j){
                amount = parseFloat(this.amountToBePaid[j]);
                serviceCharge = rate*amount;
                serviceChargeDecimel = Math.floor(serviceCharge);
                serviceChargeV = serviceCharge - serviceChargeDecimel;
                if(serviceChargeV==0){
                  serviceCharge = serviceChargeDecimel;
                } else if(serviceChargeV <= .5){
                  serviceCharge = serviceChargeDecimel+.5;
                } else{
                  serviceCharge = serviceChargeDecimel+1;
                }

                if(this.event.degree.college.minimum_tap_amount){
                  let minimumTapAmount = parseFloat(this.event.degree.college.minimum_tap_amount);
                  if(minimumTapAmount>serviceCharge){
                    serviceCharge = minimumTapAmount;
                  }
                }

                if(this.event.degree.college.maximum_tap_amount){
                  let maximumTapAmount = parseFloat(this.event.degree.college.maximum_tap_amount);
                  if(maximumTapAmount<serviceCharge){
                    serviceCharge = maximumTapAmount;
                  }
                }
                
                let totalAmountToPay = serviceCharge+amount
                this.amountToBePaidTotalAmount.push(totalAmountToPay.toString());
                if(this.amountToBePaidStr != ""){
                  this.amountToBePaidStr += ","+this.amountToBePaid[j];
                }else{
                  this.amountToBePaidStr = this.amountToBePaid[j];
                }
              }
              this.eventSetting.amount = this.amountToBePaidTotalAmount[0];
            }
          }
        }
        this.student.amount_str = amount_str;
        if(this.student.payment_type== "multiple"){
          let amounts = this.student.amount.split("#");
          // let serviceChargeStr = "";
          // let totalAmountStr = "";
          this.student.service_charge_str = "";
          this.student.total_amount_str = "";
          for(let i=0;i<amounts.length;++i){
            amount = parseFloat(amounts[i]);
            serviceCharge = rate*amount;
            serviceChargeDecimel = Math.floor(serviceCharge);
            serviceChargeV = serviceCharge - serviceChargeDecimel;
            if(serviceChargeV==0){
              serviceCharge = serviceChargeDecimel;
            } else if(serviceChargeV <= .5){
              serviceCharge = serviceChargeDecimel+.5;
            } else{
              serviceCharge = serviceChargeDecimel+1;
            }

            if(this.event.degree.college.minimum_tap_amount){
              let minimumTapAmount = parseFloat(this.event.degree.college.minimum_tap_amount);
              if(minimumTapAmount>serviceCharge){
                serviceCharge = minimumTapAmount;
              }
            }

            if(this.event.degree.college.maximum_tap_amount){
              let maximumTapAmount = parseFloat(this.event.degree.college.maximum_tap_amount);
              if(maximumTapAmount<serviceCharge){
                serviceCharge = maximumTapAmount;
              }
            }

            this.student.service_charge += serviceCharge;
            if(this.student.service_charge_str != ""){
              this.student.service_charge_str += ","+serviceCharge.toString();
            }else{
              this.student.service_charge_str += serviceCharge.toString();
            }
            let totalAmountNow = serviceCharge+amount
            this.student.total_amount += totalAmountNow;

            if(this.student.total_amount_str != ""){
              this.student.total_amount_str += ","+totalAmountNow;
            }else{
              this.student.total_amount_str += totalAmountNow;
            }
            // this.eventSetting.amount = this.student.total_amount;
          }
        }else{
          amount = parseFloat(this.student.amount);
          serviceCharge = rate*amount;
          serviceChargeDecimel = Math.floor(serviceCharge);
          serviceChargeV = serviceCharge - serviceChargeDecimel;
          if(serviceChargeV==0){
            serviceCharge = serviceChargeDecimel;
          } else if(serviceChargeV <= .5){
            serviceCharge = serviceChargeDecimel+.5;
          } else{
            serviceCharge = serviceChargeDecimel+1;
          }
          if(this.event.degree.college.minimum_tap_amount){
            let minimumTapAmount = parseFloat(this.event.degree.college.minimum_tap_amount);
            if(minimumTapAmount>serviceCharge){
              serviceCharge = minimumTapAmount;
            }
          }

          if(this.event.degree.college.maximum_tap_amount){
            let maximumTapAmount = parseFloat(this.event.degree.college.maximum_tap_amount);
            if(maximumTapAmount<serviceCharge){
              serviceCharge = maximumTapAmount;
            }
          }
          this.student.service_charge = serviceCharge;
          this.student.total_amount = this.student.service_charge + amount;
          this.eventSetting.amount = this.student.total_amount;
          this.student.service_charge_str = serviceCharge.toString();
          this.student.total_amount_str = this.student.total_amount.toString();
        }
        this.eventSetting.account = this.event.degree.college.credential.user_name;
        this.eventSetting.password = this.event.degree.college.credential.password;
        this.eventSetting.auth_token = this.event.degree.college.credential.auth_token;
        this.eventSetting.auth_api_key = this.event.degree.college.credential.auth_api_key;
        this.eventSetting.callBackUrl =
          this.apiUrl + "api/v1/payment/ipn";
        this.studentInfo = "Show";
        this.showAlert = false;
        if (this.eventSetting.type == "sandbox") {
          this.eventSetting.url = "https://auth-sandbox.tadlbd.com/oauth/token";
          var sandBoxbutton = document.getElementById("loadSandBoxJs");
          sandBoxbutton.click();
        } else if (this.eventSetting.type == "production") {
          this.eventSetting.url = "https://auth-prod.tadlbd.com/oauth/token";
          var liveButton = document.getElementById("loadLiveJs");
          liveButton.click();
        }
        // console.log(this.eventSetting);
      } else {
        this.studentInfo = "Error";
        this.showAlert = true;
      }
    },

    async completePay(payment) {
      await RequestService.post("student/payment-call-back", payment);
      this.submitStudentID();
      if (
        payment.status == "completed" &&
        this.student.payment_type == "multiple"
      ) {
        this.showPaymentMessage = true;
        this.paymentMessage =
          "Amount " + payment.amount + "BDT Successfully Paid.";
      }
    },

    async payNow() {
      this.payBtnLoading = true;
      let response = null;
      if (this.student.payment_type == "multiple") {
        let index = this.amountToBePaidTotalAmount.indexOf(
          this.eventSetting.amount
        );
        response = await RequestService.get(
          "student/" +
            this.student.id +
            "/transaction/create" +
            "?customer_mobile=" +
            this.studentMobile +
            "&amount=" +
            this.amountToBePaid[index]
        );
        // console.log(response);
      } else {
        response = await RequestService.get(
          "student/" +
            this.student.id +
            "/transaction/create" +
            "?customer_mobile=" +
            this.studentMobile
        );
      }

      this.eventSetting.requesterReferenceId = response.requester_reference_id;
      this.eventSetting.invoice_number = response.invoice_number;
      await new Promise(resolve => setTimeout(resolve, 1000));
      var payButton = document.getElementById("tokenButton");
      payButton.click();
      this.payBtnLoading = false;
    },

    toggleAlert(isShow) {
      this.showAlert = isShow;
    }
  }
};
</script>
<style scoped>
.section-profile-cover {
  height: 300px;
}
@media (max-width: 991.98px) {
  .section-profile-cover {
    height: 150px;
  }
}
</style>
